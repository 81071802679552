<div class="about-container">
  <div class="artwork">
<!--    started from https://observablehq.com/@mbostock/epicyclic-gearing-->
  </div>
  <div class="description">
    <div><img class="portrait" src="assets/isuru.jpg" alt="Isuru"></div>
    <b>Isuru Liyanage</b> theisuru@gmail.com
  </div>
</div>
<div class="footnote"></div>
