<div class="carbon-container">
  <div class="carbon-title">Carbon War</div>
  <div class="carbon-img-container">
    <img class="carbon-img" src="assets/carbon_war_1.png" alt="Carbon War">
  </div>
  <div class="carbon-subtitle">What is Carbon War</div>
  <div class="carbon-content">
    Humans have conquered Earth, defied God and Nature and have become the master.
    We have fought and we have won, but this time a different fight awaits, a fight we are not ready.
  </div>
</div>
