export const MOCK_ARTICLE_INDEX = {
  '7_habits': {
    id: '7_habits',
    title: 'The 7 Habits of Highly Effective People',
    category: 'GENERAL',
    date: '20/06/2020',
    body: 'assets/articles/7_habits.html'
  },
  monk_ferrari: {
    id: 'monk_ferrari',
    title: 'The Monk Who Sold His Ferrari',
    category: 'GENERAL',
    date: '20/06/2020',
    body: 'assets/articles/monk_ferrari.html'
  },
  rest: {
    id: 'rest',
    title: 'Richardson Maturity Model',
    category: 'SOFTWARE',
    date: '09/09/2020',
    body: 'assets/articles/rest.html'
  },
  neo4j: {
    id: 'neo4j',
    title: 'Neo4j',
    category: 'SOFTWARE',
    date: '09/09/2020',
    body: 'assets/articles/rest.html'
  },
  solr: {
    id: 'solr',
    title: 'Solr',
    category: 'SOFTWARE',
    date: '09/09/2020',
    body: 'assets/articles/rest.html'
  },
  regex: {
    id: 'regex',
    title: 'Regular Expressions',
    category: 'SOFTWARE',
    date: '09/09/2020',
    body: 'assets/articles/rest.html'
  },
  json_schema: {
    id: 'json_schema',
    title: 'JSON Schema',
    category: 'SOFTWARE',
    date: '09/09/2020',
    body: 'assets/articles/rest.html'
  },
  frontend_maven: {
    id: 'frontend_maven',
    title: 'Spring-Angular integration with frontend Maven plugin',
    category: 'SOFTWARE',
    date: '05/10/2020',
    body: 'assets/articles/frontend_maven.html'
  },
  git: {
    id: 'git',
    title: 'Git Everyday',
    category: 'SOFTWARE',
    date: '17/12/2020',
    body: 'assets/articles/git_everyday.html'
  },
  docker: {
    id: 'docker',
    title: 'Docker CheatSheet',
    category: 'SOFTWARE',
    date: '03/04/2021',
    body: 'assets/articles/docker.html'
  }
};


export const GENERAL_ARTICLES = ['The 7 Habits of Highly Effective People', 'The Monk Who Sold His Ferrari'];

export const SOFTWARE_ARTICLES = ['Neo4j', 'JSON Schema', 'Regex', 'Solr', 'REST', 'git', 'docker'];

// ontology
// json
// json schema
// regex
// solr
// neo4j

