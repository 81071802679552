<nav id="main-nav-menu">
  <a routerLink=""><img id="menu-logo" src="assets/carbon_soldier.png" alt="CARBON SOLDIER" height="50" width="100"></a>
  <!--<a routerLink="">{{title}}</a>-->
  <a routerLink="/carbon">CARBON WAR</a>
  <a routerLink="/blog">BLOG</a>
  <a routerLink="/article">ARTICLE</a>
  <a routerLink="/about">ABOUT</a>
</nav>

<router-outlet></router-outlet>

